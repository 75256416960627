@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Prevents scrolling */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Barlow', sans-serif; /* Apply Barlow font */
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; /* Places the video behind other elements */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(108deg, #001dff78 -6.28%, rgb(255 77 4 / 0%) 99.34%), linear-gradient(0deg, rgb(231 9 9 / 49%) 0%, rgb(14 162 143 / 50%) 100%), linear-gradient(0deg, rgb(0 0 0 / 81%) 0%, rgb(183 4 4 / 0%) 10%);
    background-blend-mode: hard-light;
    z-index: 1;
    pointer-events: none; /* Add this line */
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire background */
}

.container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo {
    width: 450px;
    max-width: 100%;
    transition: transform 0.2s ease-out, filter 0.2s ease-out;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.7)) invert(100%);
    position: relative;
    z-index: 2; /* Ensures the logo stays on top */
    will-change: transform; /* Hint to the browser to optimize this element for 3D transformations */
}

/* Light effect */
.light {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 60%);
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease-out;
    z-index: 1; /* Ensure it stays above other elements */
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
    .logo {
        width: 50vw;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .logo {
        width: 70vw;
    }
}

.footer {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 1rem;
    color: white; /* White text color */
    position: absolute;
    bottom: 0;
    z-index: 1; /* Ensures the footer stays behind the logo */
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
    .logo {
        width: 50vw;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .logo {
        width: 70vw;
    }
    .footer {
        font-size: 0.7rem; /* Adjust the footer text size for mobile */
    }
}

#konami-message, #end-message, #shortcut-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Press Start 2P', cursive;
    font-size: 2rem;
    color: white;
    opacity: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8); /* To hide the background */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

#shortcut-message {
    font-family: 'Barlow', sans-serif;
    font-size: 1.2rem;
}

#score, #instructions, #timer {
    position: fixed;
    font-family: 'Press Start 2P', cursive;
    font-size: 1rem;
    color: white;
    z-index: 1000;
}

#score {
    top: 10px;
    left: 10px;
}

#instructions {
    bottom: 50px; /* Moved above the footer */
    left: 10px;
}

#timer {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.flash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0.8;
}

.confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

#gradient-background {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(108deg, #1a1a3f, #3f001f);
}
